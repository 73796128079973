import React from "react";
import { Paper, Typography, Grid } from "@material-ui/core"
import Fade from 'react-reveal/Fade';

const styles = {
    paper: {
        padding: '20px',
        background: 'rgba(232, 232, 232, 0.6)',
        height: '320px'
    },
    title: {
        fontFamily: 'Berkshire Swash',
        fontSize: '30px',
        color: '#00396b',
        marginBottom: '25px'
    },
    content: {
        fontFamily: 'Courgette',
        color: '#2c2a4f'
    },
    flexColumnBetween: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}

const EventComponent = () => {
    return (
        <Grid container style={{ marginTop: '50px' }}>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Fade left>
                            <Paper elevation={3} style={{ ...styles.paper, ...styles.flexColumnBetween }}>
                                <div>
                                    <Typography style={styles.title}>Walimatul Ursy</Typography>
                                    <Typography style={styles.content}>
                                        <b>Hari/Tanggal :</b> Sabtu, 21 Januari 2023
                                    </Typography>
                                    <Typography style={styles.content}>
                                        <b>Akad Nikah : </b> 09.00 WIB
                                    </Typography>
                                    <Typography style={styles.content}>
                                        <b>Resepsi : </b> 11.00 - 14.00 WIB
                                    </Typography>
                                </div>
                                <Typography style={styles.content}>
                                    Graha Hajjah Masayu Zubaidah
                                </Typography>
                            </Paper>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Fade right>
                            <Paper elevation={3} style={styles.paper}>
                            <iframe src="https://maps.google.com/maps?q=Graha%20Hajjah%20Masayu%20Zubaidah%20Jl.%20Subang%20Pamanukan%20No.10%2C%20Sukamulya%2C%20Kec.%20Pagaden%2C%20Kabupaten%20Subang%2C%20Jawa%20Barat%2041252&t=m&z=10&output=embed&iwloc=near" style={{ border: '0', width: '100%', height: '300px' }} allowfullscreen="" loading="lazy"></iframe>
                            </Paper>
                        </Fade>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={1} sm={2}></Grid>
        </Grid>
    )
}
export default EventComponent